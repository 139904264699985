<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/meunitems">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-3"
                label="Restaurant *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :required="true"
                  :custom-label="customLabel"
                  label="username"
                  v-model="restaurantId"
                  :options="users"
                  placeholder="Select Restaurant"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.id }} - ({{ option.name }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Category List *"
                label-for="input-3"
              >
                <multiselect
                  ref="meunItems"
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="categoriesId"
                  :options="categories"
                  placeholder="Select Category"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.id }} - ({{ option.name }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="name"
                  type="text"
                  required
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Price € * "
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="price"
                  @keypress="onlyForCurrency"
                  required
                  placeholder="Enter Price"
                ></b-form-input>
              </b-form-group>

              <!-- <b-form-group
                id="input-group-3"
                label="Cooking Time:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="cookingtime"
                  :options="cookingtimes"
                  required
                ></b-form-select>
              </b-form-group> -->

              <!-- <b-form-group
                id="input-group-2"
                label="Alergies:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="alergies"
                  type="text"
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group> -->
              <h5>Allergies:</h5>

              <div class="row" style="margin-bottom:13px!important">
                <div
                  class="col-2"
                  v-for="alergen in alergies"
                  :value="alergen.id"
                  v-bind:key="alergen.id"
                >
                  <b-form-checkbox :value="alergen.id" v-model="alergy">
                    {{ alergen.name }}
                  </b-form-checkbox>
                </div>
              </div>

              <b-form-group
                id="input-group-2"
                label="Short Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="short_description"
                  placeholder="Enter Short Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Long Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="long_description"
                  placeholder="Enter Long Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox v-model="isrecomended" value="1">
                  Is Recomended
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Type *">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="type"
                  name="radio-options"
                >
                  <b-form-radio value="1">Delivery</b-form-radio>
                  <b-form-radio value="0">Pickup</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Cover Image * "
                label-for="input-2"
              >
                <input type="file" @change="coverImageShow" /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="coverimageUrl"
                  :src="coverimageUrl"
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteDeliveryAddress="deleteDeliveryAddress"
            @closeModal="closeModal"
          />
        </template>
        <br /><br />
        <div class="card">
          <input
            style="padding:10px"
            type="file"
            ref="file"
            multiple="multiple"
            @change="submitFiles"
          />
          <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
            <b-row>
              <b-col-4
                v-for="(image, key) in imagesArray"
                :key="key"
                class="p-3"
              >
                <button
                  style="color:white"
                  class="close ml-1"
                  @click.prevent="removeImage(image)"
                >
                  &times;
                </button>
                <b-img
                  style="height: 150px;max-width: 165px;"
                  thumbnail
                  fluid
                  :src="getLogo(image)"
                  alt="Image 1"
                ></b-img>
              </b-col-4>
            </b-row> </b-container
          ><br />

          <template>
            <ImagesViewTable @deleteData="deleteData" :loans="loans" />
          </template>

          <div class="col-sm-2 col-md-2">
            <b-button class="btn-info" ref="save" type="submit">Save</b-button>
          </div>
          <br />
        </div>

        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";
// import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import VerficationModal from "./VerficationModal";
import ImagesViewTable from "@/view/pages/MenuItem/ImagesViewTable";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    // VueTimepicker,
    Multiselect,
    VerficationModal,
    ImagesViewTable,
  },
  data() {
    return {
      restaurantId: null,
      categoriesId: null,
      changeClasss: false,
      categories: [],
      type: "1",
      isrecomended: "0",
      cookingTime: "",
      // alergies: "",
      headerTitle: "Add Menu Item",
      long_description: "",
      short_description: "",
      name: "",
      size: null,
      id: "",
      users: [],
      imagesShow: false,
      showKitchenDetailModal: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      coverimageUrl: null,
      coverImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      editMeunItem: "",
      show: true,
      cookingtime: null,
      platForm: "testing",
      alergies: [],
      alergy: [],
      cookingtimes: [
        { text: "Select Cooking Time", value: null },
        "30 Min",
        "60 Min",
        "90 Min",
        "120 Min",
      ],
    };
  },

  mounted() {
    document.title = "Add Menu Item ";
    this.getAllRestaurant();
    this.getAllCategory();
    this.getAlergies();
    if (this.$router.history.current.path == "/editmeunitem") {
      document.title = "Edit Menu Item ";
      this.showImagesViewTable = true;
      this.headerTitle = "Edit Menu Item";
      this.editDataApppend();
    }
  },
  methods: {
    editDataApppend() {
      let editMeunItem = JSON.parse(localStorage.getItem("editMeunItem"));
      this.editMeunItem = JSON.parse(localStorage.getItem("editMeunItem"));
      if (editMeunItem.coverImage) {
        this.coverimageUrl = editMeunItem.coverImage;
        this.coverImage = editMeunItem.coverImage;
      }
      (this.loans = editMeunItem.images),
        (this.restaurantId = editMeunItem.restaurant),
        (this.isrecomended = editMeunItem.is_recomended),
        (this.type = editMeunItem.type),
        (this.categoriesId = editMeunItem.category),
        (this.id = editMeunItem.id),
        (this.name = editMeunItem.name),
        (this.price = editMeunItem.price.toString().replace(".", ",")),
        (this.alergies = editMeunItem.alergies);
      if (this.platForm == "testing") {
        editMeunItem.allergens.map((x) => {
          this.alergy.push(x.id);
        });
      }
      // this.alergy = editMeunItem.allergens;
      this.short_description = editMeunItem.short_description;
      this.long_description = editMeunItem.long_description;
      this.cookingtime = editMeunItem.cooking_time;
      // this.deliveryAddressView = true;
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },
    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    closeModal(value) {
      this.isShowModel = value;
      this.showKitchenDetailModal = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editMeunItem.images.splice(index, 1);
        this.loans = this.editMeunItem.images;
        localStorage.removeItem("editMeunItem");
        localStorage.setItem("editMeunItem", JSON.stringify(this.editMeunItem));
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteDeliveryAddress(object) {
      let index = this.deliveryAddressArray.findIndex(
        (x) => x.lat === object.item.lat
      );
      this.deliveryAddressArray.splice(index, 1);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ id, name }) {
      return `${id}—(${name})`;
    },

    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 44 || this.price.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.price != null &&
        this.price.indexOf(",") > -1 &&
        this.price.split(",")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    onSubmit(evt) {
      try {
        const formData = new FormData();
        if (this.restaurantId == null) {
          this.validationMessage("Please select restaurant");
          evt.preventDefault();
          return false;
        }
        if (this.categoriesId == null) {
          this.validationMessage("Please select category");
          evt.preventDefault();
          return false;
        }

        if (this.coverImage == null) {
          this.validationMessage("Cover Image is Required");
          evt.preventDefault();
          return false;
        }

        evt.preventDefault();

        this.addLoaderClasses("save");
        formData.append("id", this.id);
        formData.append("restaurant_id", this.restaurantId.id);
        formData.append("category_id", this.categoriesId.id);
        formData.append("name", this.name);
        formData.append("price", this.price.replace(",", "."));
        formData.append("cooking_time", this.cookingtime);
        formData.append("alergies", this.alergy);
        formData.append("is_recomended", this.isrecomended);
        formData.append("type", this.type);
        formData.append("short_description", this.short_description);
        formData.append("long_description", this.long_description);
        formData.append("platform", this.platForm);
        formData.append("images", JSON.stringify(this.imagesArray));
        if (this.coverImage) {
          formData.append("coverImage", this.coverImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/user/meun-item-store", formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "meunitems" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            response.data.data.map((x) => {
              this.imagesArray.push(x);
            });
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },
    coverImageShow(e) {
      const file = e.target.files[0];
      this.coverImage = file;
      this.coverimageUrl = URL.createObjectURL(file);
    },

    getAllRestaurant() {
      const data = {
        apiFor: "menu",
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/restaurant/search-restaurant", data, {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCategory() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .get(this.$path + "/user/categories", {
            headers,
          })
          .then((response) => {
            this.categories = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAlergies() {
      const data = {
        type: "menu",
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/alergies/list", data, {
            headers,
          })
          .then((response) => {
            this.alergies = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
